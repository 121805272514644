
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '@/lib/util'
    import PageRequest from '@/store/entities/page-request'
    import SortingModel from "@/store/entities/sortingmodel";
    import AbpBase from "@/lib/abpbase";
    import DocumentDto from "@/store/entities/jobcontext/document";
    import FieldMetadataForm from "./fieldmetadata-form.vue";


    @Component({
        components: {FieldMetadataForm}
    })
    export default class FieldMetaData extends AbpBase {
        edit() {
            this.editModalShow = true;
        }

        pagerequest: PageRequest = new PageRequest();
        editModalShow: boolean = false;

        get list() {
            return this.$store.state.fieldMetadata.list;
        };

        async search() {
            this.$store.commit('fieldMetadata/setCurrentPage', 1);
            await this.getPage();
        }

        get loading() {
            return this.$store.state.fieldMetadata.loading;
        }

        pageChange(page: number) {
            this.$store.commit('fieldMetadata/setCurrentPage', page);
            this.getPage();
        }

        pageSizeChange(pageSize: number) {
            this.$store.commit('fieldMetadata/setPageSize', pageSize);
            this.getPage();
        }

        async getPage() {
            this.pagerequest.maxResultCount = this.pageSize;
            this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

            await this.$store.dispatch({
                type: 'fieldMetadata/getAll',
                data: this.pagerequest
            })
        }

        get pageSize() {
            return this.$store.state.fieldMetadata.pageSize;
        }

        get totalCount() {
            return this.$store.state.fieldMetadata.totalCount;
        }

        get currentPage() {
            return this.$store.state.fieldMetadata.currentPage;
        }

        async changeSort(data) {
            if (this.pagerequest.sorting == null) {
                this.pagerequest.sorting = [];
            }
            let item = this.pagerequest.sorting.find(x => x.fieldName === data.key)
            if (item == null) {
                let sortingModel = new SortingModel();
                sortingModel.fieldName = data.key;
                sortingModel.isDescendingDirection = data.order === "desc";
                this.pagerequest.sorting.push(sortingModel)
            } else {
                item.isDescendingDirection = data.order === "desc";
            }
            await this.getPage()
        }

        columns = [{
            title: this.L('Code'),
            key: 'number',
            sortable: 'custom'
        }, {
            title: this.L('Name'),
            key: 'description',
            sortable: 'custom'
        }, {
            title: this.L('Section'),
            key: 'section',
            sortable: 'custom',
        }, {
            title: this.L('Color'),
            width: 75,
            slot: 'color'
        },  {
            title: this.L('PositionType'),
            key: 'positionType',
            sortable: 'custom'
        },{
            title: this.L('Category'),
            key: 'category',
            sortable: 'custom',
        }, {
            title: this.L('Actions'),
            key: 'Actions',
            width: 100,
            render: (h: any, params: any) => {
                return h('div', [
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                this.$store.commit('fieldMetadata/edit', await this.$store.dispatch({
                                    type: 'fieldMetadata/get',
                                    data: params.row.id
                                }));
                                this.edit();
                            }
                        }
                    }, this.L('Edit'))
                ])
            }
        }]

        async created() {
            await this.getPage();
        }
    }
